var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-project-sidebar","visible":_vm.isAddNewProjectSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.resetForm,"change":function (val) { return _vm.$emit('update:is-add-new-project-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Ajouter Project")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('validation-provider',{attrs:{"name":"amendment","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{staticClass:"mb-3 text-bold",attrs:{"label-for":"amendment","state":_vm.getValidationState(validationContext)}},[_c('b-form-checkbox',{model:{value:(_vm.toggleProjectType),callback:function ($$v) {_vm.toggleProjectType=$$v},expression:"toggleProjectType"}},[_vm._v(" Avenant ")]),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"amendment","rules":_vm.toggleProjectType ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.toggleProjectType)?_c('b-form-group',{attrs:{"label":_vm.fetchingProject
                ? 'Projets parent (En cours de récupération...)'
                : 'Projets parent',"label-for":"amendment"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.projectsList,"reduce":function (o) { return o.value; },"clearable":false,"placeholder":"Sélectionnez ou Recherchez votre projet","input-id":"amendment"},on:{"input":_vm.onProjectParentChange},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                var search = ref.search;
                var searching = ref.searching;
return [(searching)?[_vm._v(" Pas de résultat pour "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Recherche un projet...")])]}}],null,true),model:{value:(_vm.projectData.parent_id),callback:function ($$v) {_vm.$set(_vm.projectData, "parent_id", $$v)},expression:"projectData.parent_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Type","label-for":"type","state":_vm.getValidationState(validationContext)}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeOptions,"reduce":function (o) { return o.value; },"clearable":false,"placeholder":"Choisir le type","input-id":"type"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                var search = ref.search;
                var searching = ref.searching;
return [(searching)?[_vm._v(" Pas de résultat pour "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Recherche un type...")])]}}],null,true),model:{value:(_vm.projectData.type),callback:function ($$v) {_vm.$set(_vm.projectData, "type", $$v)},expression:"projectData.type"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Statut","label-for":"status","state":_vm.getValidationState(validationContext)}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (o) { return o.key; },"clearable":false,"placeholder":"Choisir le statut","input-id":"status"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                var search = ref.search;
                var searching = ref.searching;
return [(searching)?[_vm._v(" Pas de résultat pour "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Recherche un statut...")])]}}],null,true),model:{value:(_vm.projectData.status),callback:function ($$v) {_vm.$set(_vm.projectData, "status", $$v)},expression:"projectData.status"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Pays","label-for":"country","state":_vm.getValidationState(validationContext)}},[_c('v-select',{attrs:{"label":"name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countries,"clearable":false,"placeholder":"Choisir le pays","input-id":"country"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                var search = ref.search;
                var searching = ref.searching;
return [(searching)?[_vm._v(" Pas de résultat pour "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Recherche un pays...")])]}}],null,true),model:{value:(_vm.projectData.country),callback:function ($$v) {_vm.$set(_vm.projectData, "country", $$v)},expression:"projectData.country"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"year"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Année","label-for":"year"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"year","placeholder":"Année du Projet","config":_vm.flatConfig},model:{value:(_vm.projectData.year),callback:function ($$v) {_vm.$set(_vm.projectData, "year", $$v)},expression:"projectData.year"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"customer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Client","label-for":"customer","state":_vm.getValidationState(validationContext)}},[_c('vue-autosuggest',{attrs:{"suggestions":_vm.customers,"input-props":_vm.inputProps,"limit":10},on:{"selected":_vm.onSelectedCustomer,"input":_vm.onCustomerChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var suggestion = ref.suggestion;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"detail ml-50"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(suggestion.item.name)+" ")])],1)])]}}],null,true),model:{value:(_vm.projectData.customer),callback:function ($$v) {_vm.$set(_vm.projectData, "customer", $$v)},expression:"projectData.customer"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Bailleur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Bailleur","label-for":"bailleur"}},[_c('b-form-input',{attrs:{"id":"bailleur","state":_vm.getValidationState(validationContext),"trim":""},model:{value:(_vm.projectData.details.lessor),callback:function ($$v) {_vm.$set(_vm.projectData.details, "lessor", $$v)},expression:"projectData.details.lessor"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Département Concerné","label-for":"concerned_department","state":_vm.getValidationState(validationContext)}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.companyList(),"reduce":function (o) { return o.ref; },"clearable":false,"placeholder":"Choisir le département concerné","input-id":"status"},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                var search = ref.search;
                var searching = ref.searching;
return [(searching)?[_vm._v(" Pas de résultat pour "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v("Recherche un département Concerné...")])]}}],null,true),model:{value:(_vm.projectData.details.concerned_department),callback:function ($$v) {_vm.$set(_vm.projectData.details, "concerned_department", $$v)},expression:"projectData.details.concerned_department"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"Description","label-for":"project-description"}},[_c('quill-editor',{staticClass:"border-bottom-0",attrs:{"id":"quil-content","options":_vm.editorOption},model:{value:(_vm.projectData.brief),callback:function ($$v) {_vm.$set(_vm.projectData, "brief", $$v)},expression:"projectData.brief"}}),_c('div',{staticClass:"d-flex justify-content-end border-top-0",attrs:{"id":"quill-toolbar"}},[_c('button',{staticClass:"ql-bold"}),_c('button',{staticClass:"ql-italic"}),_c('button',{staticClass:"ql-underline"}),_c('button',{staticClass:"ql-align"}),_c('button',{staticClass:"ql-link"})])],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"disabled":invalid || _vm.isLoading,"variant":"primary","type":"submit"}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Ajouter ")],1),_c('b-button',{attrs:{"disabled":_vm.isLoading,"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Annuler ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }