<template>
  <div>
    <project-list-add-new
      :is-add-new-project-sidebar-active.sync="isAddNewProjectSidebarActive"
      :type-options="typeOptions"
      :status-options="statusOptions"
      :countries="countryOptions"
      :limit="suggestLimit"
      @on-customer-change="onInputChange"
      @on-country-change="onInputChange"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <projects-list-filters
      :state-filter.sync="stateFilter"
      :type-filter.sync="typeFilter"
      :status-filter.sync="statusFilter"
      :state-options="stateOptions"
      :type-options="typeOptions"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Afficher</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Projets</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="customerQuery"
                class="d-inline-block mr-1"
                placeholder="Client..."
              />
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Rechercher..."
              />
              <b-button
                v-if="$can('create_project', 'Projets')"
                variant="primary"
                @click="isAddNewProjectSidebarActive = true"
              >
                <span class="text-nowrap">Ajouter Projet</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refProjectListTable"
        class="position-relative"
        hover
        :items="fetchProjects"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Aucun enregistrements correspondants trouvés"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Code -->
        <template #cell(code)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                v-if="!data.item.deleted_at"
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.code)"
                :variant="`light-${resolveProjectTypeVariant(data.item.role)}`"
                :to="{ name: 'projects-view', params: { id: data.item.uid } }"
              />
              <b-avatar
                v-else
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.code)"
                :variant="`light-${resolveProjectTypeVariant(data.item.role)}`"
              />
            </template>
            <b-link
              v-if="$can('read_project', 'Projets')"
              :to="{ name: 'projects-view', params: { id: data.item.uid } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.code }}<sup class="ml-1" :class="projectStateClass(data.item.inventory).class"><span class="p-1">{{ projectStateClass(data.item.inventory).text }}</span></sup>
            </b-link>
            <span v-else class="font-weight-bold d-block text-nowrap">
              {{ data.item.code }}<sup class="ml-1" :class="projectStateClass(data.item.inventory).class"><span class="p-1">{{ projectStateClass(data.item.inventory).text }}</span></sup>
            </span>
            <small class="text-muted text-truncate">{{
              data.item.customer.name
            }}</small>
          </b-media>
        </template>

        <!-- Column: Brief -->
        <template #cell(brief)="data">
          <div
            class="text-truncate text-wrap"
            style="max-width: 150px; max-height: 60px"
          >
            <div v-html="data.item.brief"></div>
          </div>
        </template>

        <!-- Column: Type -->
        <template #cell(type)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveProjectTypeIcon(data.item.type)"
              size="18"
              class="mr-50"
              :class="`text-${resolveProjectTypeVariant(data.item.type)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.type
            }}</span>
          </div>
        </template>

        <!-- Column: Created At -->
        <template #cell(created_at)="data">
          <div class="text-wrap">{{ formatDate(data.item.created_at) }}</div>
        </template>

        <!-- Column: Status -->
        <!-- <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveProjectStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template> -->

        <!-- Column: responsible -->
        <template #cell(responsible)="data">
          <span class="align-text-top text-capitalize">{{
            data.item.assignee ? data.item.assignee.name : ""
          }}</span>
        </template>

        <!-- Column: Actions -->
        <template v-if="$can('read_project', 'Projets') || $can('edit_project', 'Projets')" #cell(actions)="data">
          <b-dropdown
            v-if="!data.item.deleted_at"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$can('read_project', 'Projets')"
              :to="{ name: 'projects-view', params: { id: data.item.uid } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Détails</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('edit_project', 'Projets')"
              :to="{ name: 'projects-edit', params: { id: data.item.uid } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifier</span>
            </b-dropdown-item>
          </b-dropdown>
          <b-badge
            v-else
            pill
            :variant="`light-danger`"
            class="text-capitalize"
          >
            <feather-icon icon="XIcon" />
          </b-badge>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
              {{ dataMeta.of }} Projets</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProjects"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText, formatDate } from "@core/utils/filter";
import ProjectsListFilters from "./ProjectsListFilters.vue";
import useProjectsList from "./useProjectsList";
import ProjectListAddNew from "./ProjectListAddNew.vue";

export default {
  name: "ProjectsList",
  components: {
    ProjectsListFilters,
    ProjectListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup() {
    const isAddNewProjectSidebarActive = ref(false);

    const typeOptions = [
      { label: "Étude", value: "étude" },
      { label: "Contrôle", value: "contrôle" },
      { label: "Étude et Contrôle", value: "étude et contrôle" },
    ];

    onMounted(() => {
      let props = ["customers", "countries", "status"];
      fetchProjectProperties(props);
    });
    const {
      fetchProjects,
      fetchProjectProperties,
      tableColumns,
      perPage,
      currentPage,
      totalProjects,
      dataMeta,
      perPageOptions,
      customerQuery,
      searchQuery,
      sortBy,
      suggestLimit,
      isSortDirDesc,
      refProjectListTable,
      onInputChange,
      refetchData,

      // UI
      resolveProjectTypeVariant,
      resolveProjectTypeIcon,
      resolveProjectStatusVariant,
      projectStateClass,

      // Extra Filters
      stateFilter,
      typeFilter,
      statusFilter,
      stateOptions,
      countryOptions,
      statusOptions,
    } = useProjectsList();

    return {
      // Sidebar
      isAddNewProjectSidebarActive,

      fetchProjects,
      tableColumns,
      perPage,
      currentPage,
      totalProjects,
      dataMeta,
      perPageOptions,
      searchQuery,
      customerQuery,
      sortBy,
      suggestLimit,
      isSortDirDesc,
      refProjectListTable,
      onInputChange,
      refetchData,

      // Filter
      formatDate,
      avatarText,

      // UI
      resolveProjectTypeVariant,
      resolveProjectTypeIcon,
      resolveProjectStatusVariant,
      projectStateClass,

      stateOptions,
      typeOptions,
      statusOptions,

      // Extra Filters
      stateFilter,
      typeFilter,
      statusFilter,
      countryOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
