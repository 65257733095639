<template>
  <b-sidebar
    id="add-new-project-sidebar"
    :visible="isAddNewProjectSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-project-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Ajouter Project</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit, invalid }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- amendment -->
          <validation-provider
            #default="validationContext"
            name="amendment"
            rules="required"
          >
            <b-form-group
              label-for="amendment"
              class="mb-3 text-bold"
              :state="getValidationState(validationContext)"
            >
              <b-form-checkbox v-model="toggleProjectType">
                Avenant
              </b-form-checkbox>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Amendment Project -->
          <validation-provider
            #default="{ errors }"
            name="amendment"
            :rules="toggleProjectType ? 'required' : ''"
          >
            <b-form-group
              v-if="toggleProjectType"
              :label="
                fetchingProject
                  ? 'Projets parent (En cours de récupération...)'
                  : 'Projets parent'
              "
              label-for="amendment"
            >
              <v-select
                v-model="projectData.parent_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="projectsList"
                :reduce="(o) => o.value"
                :clearable="false"
                placeholder="Sélectionnez ou Recherchez votre projet"
                input-id="amendment"
                @input="onProjectParentChange"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Pas de résultat pour <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5">Recherche un projet...</em>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Type -->
          <validation-provider
            #default="validationContext"
            name="Type"
            rules="required"
          >
            <b-form-group
              label="Type"
              label-for="type"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="projectData.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :reduce="(o) => o.value"
                :clearable="false"
                placeholder="Choisir le type"
                input-id="type"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Pas de résultat pour <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5">Recherche un type...</em>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group
              label="Statut"
              label-for="status"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="projectData.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                :reduce="(o) => o.key"
                :clearable="false"
                placeholder="Choisir le statut"
                input-id="status"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Pas de résultat pour <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5">Recherche un statut...</em>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Country -->
          <validation-provider
            #default="validationContext"
            name="country"
            rules="required"
          >
            <b-form-group
              label="Pays"
              label-for="country"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="projectData.country"
                label="name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                :clearable="false"
                placeholder="Choisir le pays"
                input-id="country"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Pas de résultat pour <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5">Recherche un pays...</em>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Project Year -->
          <validation-provider #default="validationContext" name="year">
            <b-form-group label="Année" label-for="year">
              <flat-pickr
                id="year"
                v-model="projectData.year"
                placeholder="Année du Projet"
                :config="flatConfig"
                class="form-control"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Customer -->
          <validation-provider
            #default="validationContext"
            name="customer"
            rules="required"
          >
            <b-form-group
              label="Client"
              label-for="customer"
              :state="getValidationState(validationContext)"
            >
              <vue-autosuggest
                :suggestions="customers"
                v-model="projectData.customer"
                :input-props="inputProps"
                :limit="10"
                @selected="onSelectedCustomer"
                @input="onCustomerChange"
              >
                <template slot-scope="{ suggestion }">
                  <div class="d-flex align-items-center">
                    <div class="detail ml-50">
                      <b-card-text class="mb-0">
                        {{ suggestion.item.name }}
                      </b-card-text>
                    </div>
                  </div>
                </template>
              </vue-autosuggest>

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Bailleur -->
          <validation-provider
            #default="validationContext"
            name="Bailleur"
            rules="required"
          >
            <b-form-group label="Bailleur" label-for="bailleur">
              <b-form-input
                id="bailleur"
                v-model="projectData.details.lessor"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Concerned departement -->
          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group
              label="Département Concerné"
              label-for="concerned_department"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="projectData.details.concerned_department"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companyList()"
                :reduce="(o) => o.ref"
                :clearable="false"
                placeholder="Choisir le département concerné"
                input-id="status"
              >
                <template v-slot:no-options="{ search, searching }">
                  <template v-if="searching">
                    Pas de résultat pour <em>{{ search }}</em
                    >.
                  </template>
                  <em v-else style="opacity: 0.5"
                    >Recherche un département Concerné...</em
                  >
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <b-form-group label="Description" label-for="project-description">
            <quill-editor
              id="quil-content"
              v-model="projectData.brief"
              :options="editorOption"
              class="border-bottom-0"
            />
            <div
              id="quill-toolbar"
              class="d-flex justify-content-end border-top-0"
            >
              <!-- Add a bold button -->
              <button class="ql-bold" />
              <button class="ql-italic" />
              <button class="ql-underline" />
              <button class="ql-align" />
              <button class="ql-link" />
            </div>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="invalid || isLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              Ajouter
            </b-button>
            <b-button
              :disabled="isLoading"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BSpinner,
  BCardText,
} from "bootstrap-vue";
import { VueAutosuggest } from "vue-autosuggest";
import flatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
import useProjectsList from "../projects-list/useProjectsList";
import store from "@/store";

export default {
  name: "ProjectListAddNew",
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCardText,
    BSpinner,
    BFormCheckbox,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    // 3rd party packages
    VueAutosuggest,
    vSelect,
    flatPickr,
    quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewProjectSidebarActive",
    event: "update:is-add-new-project-sidebar-active",
  },
  props: {
    isAddNewProjectSidebarActive: {
      type: Boolean,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    customers: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup(props, { emit }) {
    const toast = useToast();

    const flatConfig = ref({
      wrap: true,
      altFormat: "Y",
      dateFormat: "Y",
      // defaultDate: new Date(),
      locale: French, // locale for this instance only
    });

    const projectsList = ref([]);

    const blankProjectData = {
      type: "",
      status: "",
      year: null,
      brief: "",
      customer: "",
      parent_id: null,
      details: {
        lessor: "", // bailleur
        concerned_department: "",
        // deposit_date: new Date(),
        deposit_date: null,
        deposit_place: "",
      },
    };

    const { companyList } = useProjectsList();

    const inputProps = ref({
      id: "autosuggest__input",
      class: "form-control",
      placeholder: "Taper le nom du client",
      disabled: false,
    });

    const editorOption = {
      modules: {
        toolbar: "#quill-toolbar",
      },
      placeholder: "Écrivez une description brieve du projet",
    };

    const isLoading = ref(false);
    const toggleProjectType = ref(false);
    const fetchingProject = ref(false);

    const projectData = ref(JSON.parse(JSON.stringify(blankProjectData)));
    const resetProjectData = () => {
      projectData.value = JSON.parse(JSON.stringify(blankProjectData));
    };

    const onSelectedCustomer = (inputOption) => {
      Object.assign(projectData.value, { customer: inputOption.item.name });
    };

    watch(toggleProjectType, (val) => {
      if (val) {
        inputProps.value.disabled = true
        getProjects()
      } else {
        Object.assign(projectData.value, { parent_id: null });
        inputProps.value.disabled = false
      };
    });

    // fetch porject that will be a parent for amendment
    const getProjects = () => {
      fetchingProject.value = true;

      if (toggleProjectType) {
        store.dispatch("project/onlyParents").then((projects) => {
          if (projects.length > 0) {
            projectsList.value = projects.map((p) => {
              let strippedHtml = p.brief.replace(/<[^>]+>/g, "");
              return { label: `${p.code} - ${strippedHtml}`, value: p.uid, customer: p.customer?.name };
            });
          } else {
            projectsList.value = [{ text: "Aucun projet retrouvé...", value: null }];
          }
          fetchingProject.value = false;
        });
      }
    };

    const onSubmit = () => {
      isLoading.value = true;
      store
        .dispatch("project/addProject", projectData.value)
        .then((response) => {
          if (response.success) {
            emit("update:is-add-new-project-sidebar-active", false);
            emit("refetch-data");
            toast({
              component: ToastificationContent,
              props: {
                title: "Projet enregisté avec succès.",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            resetProjectData()
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: "Problème d'enregistrement du projet",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          toast({
            component: ToastificationContent,
            props: {
              title:
                "Une erreur est survenue lors de l'enregistrement du projet.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          isLoading.value = false
        });
    };
    const onCustomerChange = (input) => {
      emit("on-customer-change", input, "customer");
    };

    const onProjectParentChange = (parentUid) => {
      const parent = projectsList.value.find((p) => p.value == parentUid)
      if(parent !== undefined && parent?.customer !== undefined)  Object.assign(projectData.value, { customer: parent.customer });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetProjectData);

    return {
      onProjectParentChange,
      flatConfig,
      projectData,
      inputProps,
      onSelectedCustomer,
      onCustomerChange,
      onSubmit,
      getProjects,

      refFormObserver,
      getValidationState,
      resetForm,
      isLoading,
      toggleProjectType,
      fetchingProject,

      // UI
      editorOption,
      companyList,
      projectsList,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
