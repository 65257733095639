import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import { onFieldChange } from "@/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useProjectsList() {
  // Use toast
  const toast = useToast();

  const refProjectListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "code", sortable: true },
    { key: "brief" },
    { key: "type" },
    {
      key: "created_at",
      label: "Crée le",
      formatter: title,
      sortable: true,
    },
    // { key: 'status' },
    { key: "responsible", label: "Responsable" },
    { key: "actions" },
  ];
  const stateOptions = [
    { label: "Fermé", value: "closed" },
    { label: "Perdu", value: "lost" },
  ];
  const perPage = ref(10);
  const totalProjects = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const customerQuery = ref("");
  const sortBy = ref("created_at");
  const suggestLimit = ref(10);
  const isSortDirDesc = ref(true);
  const dataCustomers = ref(null);
  const countryOptions = ref();
  const statusOptions = ref([]);
  const stateFilter = ref(null);
  const typeFilter = ref(null);
  const statusFilter = ref(null);
  const customersFilter = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refProjectListTable.value
      ? refProjectListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProjects.value,
    };
  });

  const projectStateClass = (projectInv) => {
    if(projectInv.set_as_lost == true) {
        return { class: "border border-1 border-danger rounded-pill text-danger", text: "Perdu"}
    } else if(projectInv.is_closed == true) {
        return { class: "border border-1 border-secondary rounded-pill text-secondary", text: "Fermé"}
    }
    return { class: "", text: ""}
  };

  const refetchData = () => {
    refProjectListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      customerQuery,
      searchQuery,
      stateFilter,
      typeFilter,
      statusFilter,
    ],
    () => {
      refetchData();
    }
  );

  // get all project's properties to create or edit a project
  const fetchProjectProperties = (properties) => {
    let params = {
      props: properties,
    };
    store
      .dispatch("project/fetchProperties", params)
      .then((properties) => {
        dataCustomers.value = properties.customers;
        countryOptions.value = properties.countries;
        statusOptions.value = properties.status;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur de récupération des données (clients)",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const fetchProjects = (ctx, callback) => {
    store
      .dispatch("project/fetchProjects", {
        q: searchQuery.value,
        customer: customerQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        type: typeFilter.value,
        status: statusFilter.value,
        state: stateFilter.value,
      })
      .then((response) => {
        const { projects, total } = response;

        let projectMapped = projects.map((p) => {
            if (p.deleted_at) p._rowVariant = "danger";
            return p;
        });

        callback(projectMapped);
        totalProjects.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Erreur de récupération des projets",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveProjectTypeVariant = (type) => {
    if (type === "étude") return "primary";
    if (type === "contrôle") return "info";
    if (type === "étude et contrôle") return "success";
    return "primary";
  };

  const resolveProjectTypeIcon = (role) => {
    if (type === "étude") return "UserIcon";
    if (type === "contrôle") return "SettingsIcon";
    if (role === "étude et contrôle") return "ServerIcon";
    return "UserIcon";
  };

  const resolveProjectStatusVariant = (status) => {
    if (status === "avis à manifestation d'interêt") return "warning";
    if (status === "demande de proposition") return "success";
    if (status === "réalisation") return "secondary";
    if (status === "négociation directe") return "info";
    return "primary";
  };

  const onInputChange = (input, resource = "customer") => {
    switch (resource) {
      case "customer":
        customersFilter.value = onFieldChange(
          input,
          dataCustomers.value,
          suggestLimit.value
        );
        break;
      // case 'country':
      //     countriesFilter.value = onFieldChange(input, dataCountries.value, suggestLimit.value)
      //     break;

      default:
        break;
    }
  };

  const companyList = () => {
    const companies = JSON.parse(localStorage.getItem("COMPANY_INFO"));
    return companies?.concerned_department;
  };

  return {
    companyList,
    fetchProjects,
    fetchProjectProperties,
    tableColumns,
    perPage,
    currentPage,
    totalProjects,
    dataMeta,
    perPageOptions,
    searchQuery,
    customerQuery,
    sortBy,
    suggestLimit,
    onInputChange,
    isSortDirDesc,
    refProjectListTable,

    resolveProjectTypeVariant,
    resolveProjectTypeIcon,
    resolveProjectStatusVariant,
    projectStateClass,
    refetchData,

    // Extra Filters
    stateFilter,
    typeFilter,
    statusFilter,
    customersFilter,
    stateOptions,
    countryOptions,
    statusOptions,
  };
}
